<template>
	<Chart :width="width" :height="height" :widgetData="widgetData" :draw="draw" :drawTooltips="drawTooltips" @refreshData="(p)=>{$emit('refreshData', p)}"/>
</template>

<script>
	import * as d3 from "d3";
	import Chart from "@/components/widgets/Chart"
	export default {
		name: "LineChart",
		props: ['width', 'height', 'widgetData'],
		components: {
			Chart
		},
		methods: {
			draw(chart, points, color, index){
				let line = chart.units.append('g')
				
				line.append('path')
					.datum(points.slice(1, points.length - 1))
					.attr('fill', 'none')
					.attr('stroke', color)
					.attr('stroke-width', '3')
					.attr('d', d3.line()
						.x((d)=>{ return chart.x(d.x) })
						.y((d)=>{ return chart.y(d.y) })
					)
					.attr("stroke-dasharray",  chart.width * 2 + " " + chart.width * 2)
					.attr("stroke-dashoffset", chart.width * 2)
					.transition()
					.delay(100 * index)
					.duration(1000)
					.attr("stroke-dashoffset", 0)

				this.drawGradient(chart,points, color, line, index)
			},
			drawGradient(chart, points, color, line, index){
				let id = Math.random().toString(36).slice(2);

				let gradient = line.append('defs')
					.append('linearGradient')
					.attr('id',id )
					.attr('x2', '0%')
					.attr('y1', '100%')
					.attr('y2', '0%')
				
				gradient.append('stop')
					.attr('offset', '0%')
					.attr('stop-color', color)
					.attr("stop-opacity", 0.5)
				
				gradient.append('stop')
					.attr('offset', '0%')
					.attr('stop-color', color)
					.attr("stop-opacity", 0)
					.transition()
					.delay(400 + (index * 300))
					.duration(1000)
					.attr('offset', '100%')
					
				line.append('path')
					.datum(points)
					.attr('fill', "url(#"+id+")")
					.attr('stroke', 'none')
					.attr('stroke-width', '0')
					.attr('d', d3.line()
						.x((d)=>{ return chart.x(d.x) })
						.y((d)=>{ return chart.y(d.y) })
					)
				
			},
			drawTooltips(chart, points, color, index){
				var div = d3.select("body").append("div")
					.attr("class", 'tooltip')
					.style("opacity", 0)
					.style("display", "none")

				points.slice(1, points.length - 1).forEach((e, i) => {
					chart.points.append('circle')
						.attr("fill", color)
						.attr("cx", (d) => { return chart.x(e.x) })
						.attr("cy", (d) => { return chart.y(e.y) })
						.attr("r", 5)
						.style("cursor", "pointer")
						.on("mouseover", (ev) => {
							div.style('display', 'block')
								.transition()
								.duration(200)
								.style("opacity", .9);
							div.text(parseInt(e.y))
								.style("left", (ev.pageX + 14) + "px")
								.style("top", (ev.pageY - 18) + "px")
								.style("color", color);
						})
						.on("mouseout",() => {
							div.transition()
								.duration(500)
								.style("opacity", 0);
						})
						.attr("opacity", 0)
						.transition()
						.delay(index*100 + i* 25)
						.duration(500)
						.attr("opacity", 1)
				})
			}
		},
		mounted(){
		}
	}
</script>

<style scoped>

</style>
